/* eslint-disable import/no-duplicates */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: "AIzaSyC55Qi7T5f1d1aW1C6BzyPqGDYysjlrZVA",
  authDomain: "contrea-6e19a.firebaseapp.com",
  databaseURL: "https://contrea-6e19a.firebaseio.com",
  projectId: "contrea-6e19a",
  storageBucket: "contrea-6e19a.appspot.com",
  messagingSenderId: "985546591679",
  appId: "1:985546591679:web:7a3ec1d7b190a4bbeae50a",
  measurementId: "G-THTGC3RTV1",
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
